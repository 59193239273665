:root {
  --helio-green-color: #b4dfee;
  --helio-yellow-color: #f39415;
  --helio-dark-color: #585752;
  --helio-light-color: #f9f8f7;
  --helio-alert-color: #bd2626;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: var(--helio-dark-color);
  background-color: var(--helio-dark-color);
  background-image: url(../../public/wave.svg);
  background-position: bottom left;
  background-repeat: no-repeat;
}

h1,
h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

.error {
  font-size: 12px;
  color: var(--helio-alert-color);
  align-self: center;
}

.container {
  max-width: 60rem;
  margin: auto;
  padding: 1rem 0;
}
